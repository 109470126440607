<script lang="ts" setup>
import { toast } from "vue3-toastify";

import type { Promotion, Tournament, EventData } from "@/types";

const { t } = useT();
const { isDesktop } = useDevice();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { data: appInit } = useAppInitData();
const { promotions, refresh } = useHomePage();
const { refresh: refreshPromotionsData } = usePromotionsData({ immediate: false });
const { data: tournamentData } = useGetTournamentData();
const { bonusesData } = useBonusesData();
const { add } = useSockets();
const loginGuard = useLoginGuard();
const { open } = useNlcModals();
const { isSweepStakes } = useSwitchMode();
const { isSubscribed, handleSubscribe } = useTournamentSubscribe(tournamentData.value?.data || {});
const isRankedLeagueActive = computed(() => appInit.value?.rankLeague?.enabled);
const { prizeVaultIsActive } = useFeaturesIsActive();

const { execute: sendEmail } = useAsyncFetch({
	path: "/rest/email-lottery/participate/",
	method: "post",
	fetchOptions: {
		onResponse: ({ response }) => {
			if (response._data.success) {
				open("LazyOModalCheckEmail", { email: appInit?.value?.email });
				refresh();
				return;
			}

			toast(t("Sending error"), {
				icon: "",
				type: toast.TYPE.DEFAULT,
				theme: toast.THEME.DARK,
				position: toast.POSITION.BOTTOM_CENTER,
				transition: toast.TRANSITIONS.SLIDE,
				autoClose: 3000
			});
		}
	},
	options: {
		immediate: false
	}
});

const handleQualificationClick = () => {
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};

const handleTournamentClick = () => {
	loginGuard({
		success: () => {
			dispatchGAEvent({
				event: "click_button",
				location: "promotions",
				buttonName: tournamentData.value.data?.isSubscribed ? t("Play now") : t("coming next"),
				formName: "Right now"
			});

			open("LazyOModalRaceGames");
			handleSubscribe(tournamentData.value?.data?.id || 0);
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleEmailLotteryClick = () => {
	sendEmail();
};

const handleClick = (promotion: Promotion & { secretOffer?: boolean }) => {
	if (promotion.secretOffer) {
		open("LazyOModalEasyProfileCompleted");
	} else {
		dispatchGAEvent({ event: "click_button", location: "promotions", buttonName: "make_deposit" });

		window?.$store?.gaCash?.deposit?.({
			location: "promotions",
			type: "",
			step: "view_payments_window",
			activePackage: promotion.data?.money
		});

		window?.$cash?.$router?.push?.(`/cash/deposit-by-money/${promotion?.data?.promoOfferPreset?.id}/promoOffer/`);
	}
};

add("promotionsPage", ({ action }: EventData<Promotion>) => {
	if (action === "update") {
		refresh();
		refreshPromotionsData();
	}
});
</script>
<template>
	<div class="slider">
		<MSliderWrapper
			title="Promotions"
			data-tid="promotions"
			:list="promotions"
			icon="24/promo"
			:showAll="!appInit?.isGuest"
			:spacing="0"
			@view-all-action="navigateTo('/promotions')"
		>
			<template #custom>
				<div v-if="appInit?.scratchCardLottery?.isActive" class="keen-slider__slide" data-tid="promo-card">
					<AAnimationHoverSlide :active="isDesktop">
						<MPromotionScratchCards data-tid="promo-scratch-card" />
					</AAnimationHoverSlide>
				</div>
				<div v-if="appInit?.prizeDrops?.isActive && !appInit?.isGuest" class="keen-slider__slide" data-tid="promo-card">
					<AAnimationHoverSlide :active="isDesktop">
						<MPromotionCoinDrops data-tid="promo-coin-drop" />
					</AAnimationHoverSlide>
				</div>
			</template>

			<template #default="{ item }">
				<div class="keen-slider__slide" data-tid="promo-card">
					<MPromotionSkeleton v-if="item.skeleton" />
					<AAnimationHoverSlide v-else :active="isDesktop">
						<MPromotionInvite
							v-if="item.type === 'invite'"
							:type="item.type"
							:image="item.package?.imagePopup"
							:title="item.package?.title"
							:coins="item.package?.coins || 0"
							:entries="item.package?.entries || 0"
							:howWorks="item.package?.howWorks"
							:available="!!item?.available"
							:referralUrl="item.data?.referralUrl || ''"
						/>
						<MPromotionVipClub
							v-else-if="isRankedLeagueActive && item.type === 'rankLeague'"
							:type="item.type"
							:image="item.package?.imagePopup"
							:title="item.package?.title"
						/>
						<MPromotionWeeklyReward
							v-else-if="isRankedLeagueActive && item.type === 'rankLeagueCashback'"
							:type="item.type"
							:title="item.package?.title"
							:image="item.package?.imagePopup"
						/>
						<MPromotionVipClubBirthday
							v-else-if="isRankedLeagueActive && item.type === 'rankLeagueBirthdayGift'"
							:data="item"
							:title="item.package?.title"
						/>
						<MPromotionTournament
							v-else-if="item.type === 'tournament'"
							:type="item.type"
							:title="tournamentData?.data?.title || ''"
							:image="item.package?.imagePopup"
							:tournament="(tournamentData.data as Tournament) || {}"
							:sweepStakes="isSweepStakes"
							:subscribed="isSubscribed"
							@click="handleTournamentClick"
						/>
						<template v-else-if="item.type === 'depositStreak'">
							<MPromotionPrizeVault v-if="prizeVaultIsActive" :type="item.type" />
							<MPromotionWheelOfWins
								v-else
								:type="item.type"
								:image="item.package?.imagePopup"
								:title="item.package?.title"
								:subTitle="item.package?.subTitle"
							/>
						</template>

						<MPromotionSpookySeason
							v-else-if="item.type === 'season'"
							:promotion="item"
							:type="item.type"
							:image="item.package?.imagePopup"
							:title="item.data?.title"
							:entries="item.data?.prize || 0"
						/>
						<MPromotionSuperCoinInfo v-else-if="item.type === 'superCoinInfo'" />
						<MPromotionCard
							v-else-if="item.type === 'inviteQualification'"
							:type="item.type"
							:title="t('Only $ left to qualify', { deposit: item.data?.depositCondition || 0 })"
							:subTitle="item.package?.subTitle"
							:image="item.package?.imagePopup"
							:coins="item.package?.coins || 0"
							:entries="item.package?.entries || 0"
							imageBadge="/nuxt-img/promotions/ribbon.png"
							:badge="{ percent: t('GET'), text: t('the gift') }"
							nameButton="Buy Now"
							buttonTid="promo-invite-qa-buy"
							coinsTid="promo-invite-qa-coins"
							entriesTid="promo-invite-qa-entries"
							showBadge
							@click="handleQualificationClick"
						/>
						<MPromotionCard
							v-else-if="item.type === 'partnerReferralQualification'"
							:type="item.type"
							:title="t('Make $ purchase and get extra', { deposit: item.data?.baseline || 0 })"
							:image="item.package?.imagePopup"
							:coins="item.package?.coins || 0"
							:entries="item.package?.entries || 0"
							nameButton="Qualify"
							buttonTid="promo-referer-qa"
							coinsTid="promo-referrer-coins"
							entriesTid="promo-referrer-entries"
							@click="handleQualificationClick"
						/>
						<MPromotionCard
							v-else-if="item.type === 'emailLottery'"
							:type="item.type"
							:image="item.package?.imagePopup"
							:title="item.package?.title"
							:subTitle="item.package?.subTitle || ''"
							:coins="25000"
							:nameButton="t('Get email')"
							buttonTid="promo-get-email"
							hideLabel
							@click="handleEmailLotteryClick"
						/>
						<MPromotionDailyWheel
							v-else-if="item.type === 'dailyWheel'"
							:type="item.type"
							:image="item.package?.imagePopup"
							:title="item.package?.title"
							:subTitle="item.package?.subTitle || ''"
							:reasonTime="bonusesData?.dailyWheel?.reasonType === 'time'"
							:dateDailyWheel="bonusesData?.dailyWheel?.reasonValue || ''"
						/>
						<MPromotionBingo v-else-if="item.type === 'bingo'" :type="item.type" :image="item.package?.imagePopup" />
						<MPromotionCard
							v-else
							:image="`${baseImageUrl}${item.package?.imagePopup}`"
							:icon="item.data?.money && !item.secretOffer ? '20/promo-white' : ''"
							:type="item.type"
							:bestDeal="!!item.data?.promoOfferPreset?.bestDeal"
							:mostPopular="!!item.data?.promoOfferPreset?.mostPopular"
							:showBadge="!!(item.data?.promoOfferPreset?.badgeLabel && item.data?.promoOfferPreset?.imageBadge)"
							:imageBadge="`${baseImageUrl}${item.data?.promoOfferPreset?.imageBadge}`"
							:badge="item.badgeLabel"
							:howWorks="item.package?.howWorks"
							:subTitle="item.package?.subTitle || ''"
							:title="item.package?.title || ''"
							:coins="item.package?.coins || 0"
							:entries="item.package?.entries || 0"
							:date="item.date"
							:showAvailable="!!item.data?.promoOfferPreset?.availableTill"
							:enabled="item.data?.enabled"
							:secretOffer="item.secretOffer"
							:money="item.data?.money"
							:textTooltip="item.tooltipText"
							:usualPrice="item.useualPrice"
							:buttonTid="item.data?.enabled ? 'promo-buy-now' : null"
							@click="handleClick(item)"
						/>
					</AAnimationHoverSlide>
				</div>
			</template>
		</MSliderWrapper>
	</div>
</template>

<style lang="scss" scoped>
.slider:deep(.keen-slider) {
	margin-bottom: gutter(-6);
}

.slider:deep(.cards-feed__container) {
	width: calc(100% + 8px);
	padding-bottom: gutter(6);
	margin: 0 gutter(-1);
}

.keen-slider__slide {
	overflow: visible;
	padding: 0 8px;

	& > * {
		width: 332px;
	}
}
</style>
